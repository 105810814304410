const Linkedin = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#666666"
    role="img"
    aria-label="[title + description]"
  >
    <title>LinkedIn Icon</title>
    <desc>Headout&apos;s LinkedIn Handle</desc>

    <g clipPath="url(#clip0_95_4455)">
      <path d="M14.3043 1.00011H1.6087C1.26087 1.00011 1 1.26261 1 1.61261V14.4751C1 14.7376 1.26087 15.0001 1.6087 15.0001H14.3913C14.7391 15.0001 15 14.7376 15 14.3876V1.61261C14.913 1.26261 14.6522 1.00011 14.3043 1.00011ZM5.08696 12.9001H3.08696V6.25011H5.17391V12.9001H5.08696ZM4.13043 5.37511C3.43478 5.37511 2.91304 4.76261 2.91304 4.15011C2.91304 3.45011 3.43478 2.92511 4.13043 2.92511C4.82609 2.92511 5.34783 3.45011 5.34783 4.15011C5.26087 4.76261 4.73913 5.37511 4.13043 5.37511ZM12.8261 12.9001H10.7391V9.66261C10.7391 8.87511 10.7391 7.91261 9.69565 7.91261C8.65217 7.91261 8.47826 8.78761 8.47826 9.66261V12.9876H6.3913V6.25011H8.3913V7.12511C8.65217 6.60011 9.34783 6.07511 10.3043 6.07511C12.3913 6.07511 12.7391 7.47511 12.7391 9.22511V12.9001H12.8261Z" />
    </g>
    <defs>
      <clipPath id="clip0_95_4455">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Linkedin;
