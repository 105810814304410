const Facebook = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#666666"
    role="img"
    aria-label="[title + description]"
  >
    <title>Facebook Icon</title>
    <desc>Headout&apos;s Facebook Handle</desc>
    <g clipPath="url(#clip0_95_4461)">
      <path d="M16 8.0481C16.0003 6.51919 15.5625 5.0222 14.7385 3.73438C13.9144 2.44656 12.7385 1.42186 11.3501 0.781606C9.96173 0.141349 8.41893 -0.0876463 6.90442 0.121734C5.38991 0.331114 3.96712 0.970101 2.80451 1.96303C1.6419 2.95597 0.788165 4.26126 0.344396 5.72435C-0.0993733 7.18745 -0.114591 8.74707 0.300544 10.2185C0.71568 11.69 1.54378 13.0117 2.68679 14.0272C3.82981 15.0426 5.23986 15.7092 6.75 15.9481V10.3601H4.719V8.0481H6.75V6.2851C6.70627 5.87355 6.7536 5.45742 6.88863 5.0662C7.02366 4.67497 7.24309 4.31824 7.53136 4.02128C7.81964 3.72433 8.1697 3.49441 8.55674 3.34783C8.94379 3.20125 9.35833 3.1416 9.771 3.1731C10.371 3.18098 10.9696 3.23313 11.562 3.3291V5.3001H10.554C10.3823 5.27765 10.2077 5.29409 10.0432 5.34822C9.87874 5.40234 9.72853 5.49276 9.6037 5.61279C9.47887 5.73281 9.38263 5.87937 9.3221 6.04162C9.26157 6.20386 9.23829 6.37765 9.254 6.5501V8.0501H11.473L11.118 10.3621H9.25V15.9531C11.1322 15.6553 12.8464 14.6955 14.0838 13.2463C15.3213 11.7971 16.0008 9.95374 16 8.0481Z" />
    </g>

    <defs>
      <clipPath id="clip0_95_4461">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Facebook;
