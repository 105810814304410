export const HO_CONTACT_NUMBERS = [
  {
    countryName: '🇺🇸  United States of America',
    phoneNumber: '+1 347 897 0100',
    ctaType: 'USA phone number',
  },
  {
    countryName: '🇫🇷  France',
    phoneNumber: '+33644632370',
    ctaType: 'France phone number',
  },
  {
    countryName: '🇬🇧  United Kingdom',
    phoneNumber: '+44 117 325 5305',
    ctaType: 'UK phone number',
  },
  {
    countryName: '🇪🇸  Spain',
    phoneNumber: '+34518890338',
    ctaType: 'Spain phone number',
  },
  {
    countryName: '🇦🇺  Australia',
    phoneNumber: '+61 3 7066 3969',
    ctaType: 'Australia phone number',
  },
  {
    countryName: '🇦🇪  United Arab Emirates',
    phoneNumber: '+971 8 000 321171',
    ctaType: 'UAE phone number',
  },
  {
    countryName: '🇸🇬  Singapore',
    phoneNumber: '+6531580736',
    ctaType: 'Singapore phone number',
  },
  {
    countryName: '🇲🇾  Malaysia',
    phoneNumber: '+601548770486',
    ctaType: 'Malaysia phone number',
  },
  {
    countryName: '🇵🇱  Poland',
    phoneNumber: '+48729087896',
    ctaType: 'Poland phone number',
  },
];
