const Twitter = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#666666"
    role="img"
    aria-label="[title + description]"
  >
    <title>Twitter Icon</title>
    <desc>Headout&apos;s Twitter Handle</desc>
    <path d="M7.3396 9.80109L3.66201 14.0003H1.625L6.38817 8.55908L7.3396 9.80109Z" />
    <path d="M8.44922 5.85202L11.8165 2H13.8523L9.39208 7.10089L8.44922 5.85202Z" />
    <path d="M14.6368 14.0001H10.5401L1.36133 2H5.56178L14.6368 14.0001ZM11.102 12.7816H12.23L4.94892 3.15458H3.73863L11.102 12.7816Z" />
  </svg>
);
export default Twitter;
