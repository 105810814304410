const Youtube = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#666666"
    role="img"
    aria-label="[title + description]"
  >
    <title>Youtube Icon</title>
    <desc>Headout&apos;s Youtube Handle</desc>
    <path d="M15.8 4.80009C15.6 3.50009 15 2.60009 13.6 2.40009C11.4 2.00009 8 2.00009 8 2.00009C8 2.00009 4.6 2.00009 2.4 2.40009C1 2.60009 0.3 3.50009 0.2 4.80009C0 6.10009 0 8.00009 0 8.00009C0 8.00009 0 9.90009 0.2 11.2001C0.4 12.5001 1 13.4001 2.4 13.6001C4.6 14.0001 8 14.0001 8 14.0001C8 14.0001 11.4 14.0001 13.6 13.6001C15 13.3001 15.6 12.5001 15.8 11.2001C16 9.90009 16 8.00009 16 8.00009C16 8.00009 16 6.10009 15.8 4.80009ZM6 11.0001V5.00009L11 8.00009L6 11.0001Z" />
  </svg>
);

export default Youtube;
