const WhiteBlip = (
  <svg
    width="30"
    height="16"
    viewBox="0 0 30 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.3933 6.53701C26.2828 10.5196 21.0586 12.5051 15.6729 12.5051C11.3943 12.5051 7.18495 11.9361 3.5176 10.299C12.2823 11.2395 20.7703 10.8679 28.3933 6.53701ZM28.3933 4.87663C25.8331 4.1103 23.5266 3.8897 21.1393 3.91292C15.0847 3.95936 8.96096 5.7939 3.5522 8.19739C7.85384 4.35414 14.2544 1.55588 20.6665 1.55588C23.7226 1.55588 27.24 2.86792 28.3933 4.87663ZM29.5696 5.74746C29.5696 1.66038 23.4458 0 19.9515 0C14.4735 0 9.87203 2.00871 5.93944 4.2148C5.69725 3.77358 5.35128 3.69231 4.80925 3.62264C4.00197 3.55298 3.50607 3.51814 2.89484 3.51814C2.22596 3.51814 1.22262 3.56459 0.438411 3.69231C0.0578368 3.76197 -0.0690212 4.06386 0.0347717 4.5283C0.380748 5.7939 0.899713 6.93179 1.58013 8C1.28029 9.04499 1.38408 10.0319 2.24902 10.8447C1.94917 12.0639 1.85691 13.3991 1.90304 14.6067C1.97224 15.106 2.22596 15.2453 2.64113 15.1756C4.18649 14.9318 5.68572 14.3861 6.9889 13.6894C7.31181 13.5152 7.46173 13.3179 7.55399 13.074L11.3713 13.6662C11.9594 14.3048 12.5476 14.8505 13.2626 15.4543C13.7354 15.8026 14.2429 16 14.8656 16C17.645 15.9768 19.9861 15.5588 21.9235 14.8157C22.6847 14.5718 23.2498 13.5501 24.0917 12.1219C25.9599 11.1698 29.5696 9.38171 29.5696 5.74746Z"
      fill="white"
    />
  </svg>
);
export default WhiteBlip;
