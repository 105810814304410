const OutlinedInfoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0002 1.66659C5.39779 1.66658 1.66683 5.39754 1.66683 9.99992C1.66683 14.6023 5.39779 18.3333 10.0002 18.3333C14.6025 18.3333 18.3335 14.6023 18.3335 9.99992C18.3335 5.39755 14.6025 1.66659 10.0002 1.66659Z"
      stroke="#A46E00"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13.3333L10 9.99992"
      stroke="#A46E00"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6.66675L9.99167 6.66675"
      stroke="#A46E00"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default OutlinedInfoIcon;
